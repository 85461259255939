import axios from "axios";

/**
 * Default options
 * @type {{client: *, url: string}}
 */
const defaultOptions = {
  /**
   * Basic url
   * По умолчанию используем урл для локалки
   * @default: https://api-app-test.altpay.uk
   */
  url: 'api.test.inside.altpay.uk',

  /**
   * List of mapped urls for backend url
   */
  hosts: [
    // Production subdomains
    {
      source: 'reg.altpay.uk',
      target: 'api.altpay.uk',
    },
    // {
    //   source: 'reg.bankbee.eu',
    //   target: 'api-app.bankbee.eu'
    // },
    // {
    //   source: 'reg.gahchopay.com',
    //   target: 'api-app.gahchopay.com'
    // },
    // {
    //   source: 'reg.dmpay.biz',
    //   target: 'api-app.dmpay.biz'
    // },
    {
      source: 'reg.stage.altpay.uk',
      target: 'api.stage.altpay.uk'
    },
    {
      source: 'reg.akaruy.com',
      target: 'api.akaruy.com'
    },

    // Test subdomains
    {
      source: 'reg.test.inside.altpay.uk',
      target: 'api.test.inside.altpay.uk'
    },
    // {
    //   source: 'reg-test-bankbee.altpay.uk',
    //   target: 'api-app-test-bankbee.altpay.uk'
    // },
    // {
    //   source: 'reg-test-gahchopay.altpay.uk',
    //   target: 'api-app-test-gahchopay.altpay.uk'
    // },
    // {
    //   source: 'reg-test-dmpay.altpay.uk',
    //   target: 'api-app-test-dmpay.altpay.uk'
    // }
  ],

  /**
   * Detect the target domain that will be used as sdk url
   * @return {*|string}
   */
  detect () {
    const my_host = document.location.host
    const my_port = document.location.protocol
    const backend = this.hosts.find(i => {
      return i.source === my_host
    })

    return my_port + '//' + (backend ? backend.target : this.url)
  }
}

export default {
  install(Vue) {
    axios.defaults.baseURL = defaultOptions.detect()

    Vue.$sdk = Vue.prototype.$sdk = {
      site: {
        info () {
          return axios.get('/v1/info')
        }
      },
      registration: {
        /**
         * Create account and return id of registration
         *
         * @param type one of them : {individual|business}
         * @param validate
         * @param data
         * @returns {Promise<AxiosResponse<any>>}
         */
        start (type, data = {}, validate = 1) {
          return axios.post(`/v1/registration/${type}`, data, {
            params: {
              validate: validate
            }
          })
        },

        /**
         * Send link to continue registration
         *
         * @param email
         * @param validate
         * @return {Promise<AxiosResponse<any>>}
         */
        resume (email = '', validate = 1) {
          return axios.post('/v1/registration/resume', {
            email: email
          }, {
            params: {
              validate: validate
            }
          })
        },

        /**
         * Get full registration
         *
         * @param id
         * @return {Promise<AxiosResponse<any>>}
         */
        get (id) {
          return axios.get(`/v1/registration/${id}`)
        },

        /**
         * Completely delete registration record
         *
         * @param id
         * @return {Promise<AxiosResponse<any>>}
         */
        remove (id) {
          return axios.delete(`/v1/registration/${id}`)
        },

        company: {
          /**
           * Update company section
           *
           * @param id
           * @param data
           * @param validate
           * @return {Promise<AxiosResponse<any>>}
           */
          update (id, data, validate = 1) {
            return axios.patch(`/v1/registration/${id}/company-business`, data, {
              params: {
                validate: validate
              }
            })
          }
        },

        member: {
          /**
           * Create new member
           *
           * @param hash
           * @param validate
           * @return {Promise<AxiosResponse<any>>}
           */
          create (hash, data, validate = 1) {
            return axios.post (`/v1/registration/${hash}/member`, data, {
              params: {
                validate: validate
              }
            })
          },

          /**
           * Update member by id
           *
           * @param hash
           * @param id
           * @param data
           * @param validate
           * @return {Promise<AxiosResponse<any>>}
           */
          update (hash, id, data, validate = 1) {
            return axios.patch(`/v1/registration/${hash}/member/${id}`, data, {
              params: {
                validate: validate
              }
            })
          },

          /**
           * Delete user by id
           *
           * @param hash
           * @param id
           * @return {Promise<AxiosResponse<any>>}
           */
          delete (hash, id) {
            return axios.delete(`/v1/registration/${hash}/member/${id}`)
          }
        },

        counterparty: {
          /**
           * Create new business partner
           *
           * @param hash
           * @param data
           * @param validate
           * @return {Promise<AxiosResponse<any>>}
           */
          create (hash, data, validate = 1) {
            return axios.post(`/v1/registration/${hash}/counterparty`, data, {
              params: {
                validate: validate
              }
            })
          },

          /**
           * Update business partner member
           *
           * @param hash
           * @param id
           * @param data
           * @param validate
           * @return {Promise<AxiosResponse<any>>}
           */
          update (hash, id, data, validate = 1) {
            return axios.patch(`/v1/registration/${hash}/counterparty/${id}`, data, {
              params: {
                validate: validate
              }
            })
          },

          /**
           * Delete business partner
           *
           * @param hash
           * @param id
           * @return {Promise<AxiosResponse<any>>}
           */
          delete (hash, id) {
            return axios.delete(`/v1/registration/${hash}/counterparty/${id}`)
          }
        },

        kyc: {
          /**
           * Update KYC section
           *
           * @param hash
           * @param data
           * @param validate
           * @return {Promise<AxiosResponse<any>>}
           */
          update (hash, data, validate = 1) {
            return axios.patch(`/v1/registration/${hash}/kyc`, data, {
              params: {
                validate: validate
              }
            })
          }
        },

        /**
         * Ondata Verification
         */
        verification: {
          /**
           * Send link to continue registration on other device
           *
           * @param hash
           * @param send
           * @return {Promise<AxiosResponse<any>>}
           */
          send (hash, send = 0) {
            return axios.post(`/v1/registration/${hash}/verification`, null,{
              params: {
                send: send
              }
            })
          },

          /**
           * Check status of verification
           *
           * @param hash
           * @return {Promise<AxiosResponse<any>>}
           */
          get (hash) {
            return axios.get(`/v1/registration/${hash}/verification`)
          },

          /**
           * Save verification information
           *
           * @param token
           * @return {Promise<AxiosResponse<any>>}
           */
          save (token) {
            return axios.put(`/v1/registration/${token}/verification`)
          }
        },

        address: {
          /**
           * Create new address
           *
           * @param id
           * @param data
           * @param validate
           * @return {Promise<AxiosResponse<any>>}
           */
          create (id, data, validate = 1) {
            return axios.post(`/v1/registration/${id}/address`, data, {
              params: {
                validate: validate
              }
            })
          },

          /**
           * Update by type
           *
           * @param id
           * @param type
           * @param data
           * @param validate
           * @return {Promise<AxiosResponse<any>>}
           */
          update (id, type, data, validate = 1) {
            return axios.patch(`/v1/registration/${id}/address/${type}`, data, {
              params: {
                validate: validate
              }
            })
          },

          /**
           * Delete address by type
           *
           * @param id
           * @param type
           * @return {Promise<AxiosResponse<any>>}
           */
          delete (id, type) {
            return axios.delete (`/v1/registration/${id}/address/${type}`)
          }
        },

        /**
         * User profile
         * Only for individual
         */
        profile: {
          update (id, data, validate = 1) {
            return axios.patch(`/v1/registration/${id}/company-individual`, data, {
              params: {
                validate: validate
              }
            })
          }
        },

        wallet: {
          update (id, data, validate = 1) {
            return axios.patch(`/v1/registration/${id}/company-wallet`, data, {
              params: {
                validate: validate
              }
            })
          }
        },

        /**
         * Finish form
         *
         * @param hash
         * @param data
         * @return {*}
         */
        finish (hash, data) {
          return axios.post(`/v1/registration/${hash}/finish`, data)
        },

        /**
         * Update section of registration
         *
         * @param id
         * @param section
         * @param data
         * @param validate
         * @returns {Promise<AxiosResponse<any>>}
         */
        update (id, section, data, validate = 1) {
          return axios.patch(`/v1/registration/${id}/${section}`, data, {
            params: {
              validate: validate
            }
          })
        },

        /**
         * Files upload
         */
        files: {
          /**
           * Get conditions for file uploads
           *
           * @param config
           * @return {Promise<AxiosResponse<any>>}
           */
          conditions (config) {
            return axios.get('/v1/registration/upload', config).then(response => {
              if (response.data.extensions) {
                response.data.extensions = response.data.extensions.map(i => '.' + i)
              }
              return response
            })
          },

          /**
           * File upload action
           *
           * @param data
           * @param config
           * @returns {Promise<AxiosResponse<any>>}
           */
          upload (data, config) {
            return axios.post('/v1/registration/upload', data, config)
          },
        },

        /**
         * Return prepared list of data for filling forms
         *
         * @returns {Promise<AxiosResponse<any>>}
         */
        list () {
          return axios.get('/v1/registration/list')
        },

        /**
         * Get codes list
         *
         * @param params
         * @returns {Promise<AxiosResponse<any>>}
         */
        getSicCodesList (params) {
          const { search, page } = params

          return axios.get(`/v1/registration/list/sic-code?search=${search}`, {
            params: { page }
          })
        },

        /**
         * Send confirmation code
         *
         * @param type
         * @param data
         * @returns {Promise<AxiosResponse<any>>}
         */
        sendCode (type, data = {}) {
          return axios.post(`/v1/registration/${type}/code`, data)
        },

        /**
         * Return companies list
         *
         * @param string
         * @returns {Promise<AxiosResponse<any>>}
         */
        getCompaniesList (string) {
          return axios.get('/v1/registration/company/search', {
            params: {
              query: string
            }
          })
        }
      },
      countries: {
        /**
         * Return list of all countries
         *
         * @return {Promise<AxiosResponse<any>>}
         */
        list () {
          return axios.get('/v1/country')
        }
      }
    }
  }
}

import Vue from 'vue'
import sdk from '@/components/registration.sdk'
import cloneDeep from 'lodash.clonedeep'

Vue.use(sdk)

export default {
  state: {
    hash: null,
    type: null,

    /**
     * @type {{token: string, type: string, company: {isCompleted: boolean, errors: [], attributes: object}, counterparty: {}}}
     */
    data: null
  },
  mutations: {
    setHash(state, payload) {
      state.hash = payload
    },
    setType(state, payload) {
      state.type = payload
    },
    setData(state, payload) {
      state.data = payload
    }
  },
  actions: {
    setRegistrationType ({ commit }, payload) {
      commit('setType', payload)
    },

    /**
     *
     * @param commit
     * @param state
     * @param payload - Hash
     * @return {Promise<void>}
     */
    getRegistration ({ commit, state }, payload) {
      commit('setHash', payload)

      return Vue.$sdk.registration.get(state.hash).then(response => {
        commit('setType', response.data.type.toLowerCase())
        commit('setData', response.data)
      })
    },

    /**
     * Update registration
     *
     * @param dispatch
     * @param state
     * @return {*}
     */
    updateRegistration ({ state, dispatch }) {
      return dispatch('getRegistration', state.hash)
    },

    /**
     * Reset registration
     * @param commit
     */
    resetRegistration ({ commit }) {
      commit('setHash', null)
      commit('setData', null)
      commit('setType', null)
    }
  },
  getters: {
    getRegistrationDataPart: state => key => {
      return cloneDeep(state.data[key])
    }
  }
}

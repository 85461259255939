import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: () => import('../views/layouts/Home')
    },
    {
      path: '/:type(business|individual|wallet)',
      component: () => import('../views/layouts/Main'),
      children: [
        {
          path: '',
          name: 'CreateAccount',
          component: () => import('../views/steps/CreateAccount')
        }
      ],
      beforeEnter: (to, from, next) => {
        store.dispatch('setRegistrationType', to.params.type).then(() => {
          next()
        })
      }
    },
    {
      path: '/verification/:token',
      name: 'VerificationComplete',
      component: () => import('../views/steps/VerificationComplete'),
      props: true
    },
    {
      path: '/unavailable',
      name: 'Unavailable',
      component: () => import('../views/steps/Unavailable')
    },
    {
      path: '/error',
      name: 'Error',
      component: () => import('../views/layouts/Error')
    },
    {
      path: '/:hash/complete',
      name: 'RegistrationCompleted',
      component: () => import('../views/steps/RegistrationCompleted')
    },
    {
      path: '/:hash',
      component: () => import('../views/layouts/Main'),
      name: 'ContinueRegistration',
      children: [
        // Business
        {
          path: 'company',
          name: 'BusinessCompany',
          component: () => import('../views/business/Company')
        },
        {
          path: 'legal-address',
          name: 'LegalAddress',
          component: () => import('../views/business/LegalAddress'),
        },
        {
          path: 'operating-address',
          name: 'BusinessOperatingAddress',
          component: () => import('../views/business/OperatingAddress'),
        },
        {
          path: 'director',
          name: 'BusinessDirector',
          component: () => import('../views/business/Director')
        },
        {
          path: 'ubo',
          name: 'BusinessUBO',
          component: () => import('../views/business/UBO')
        },
        {
          path: 'partners',
          name: 'BusinessPartners',
          component: () => import('../views/business/Partners')
        },
        {
          path: 'kyc',
          name: 'BusinessKYC',
          component: () => import('../views/business/KYC')
        },

        // Individual
        {
          path: 'user-profile',
          name: 'IndividualContactInfo',
          component: () => import('../views/individual/ContactInfo')
        },

        // Company wallet
        {
          path: 'details',
          name: 'WalletDetails',
          component: () => import('../views/wallet/AdditionalInfo')
        },

        // Many types
        {
          path: 'verification/:device?',
          name: 'Verification',
          component: () => import('../views/steps/Verification')
        },
        {
          path: 'finish',
          name: 'Finish',
          component: () => {
            return import('../views/' + store.state.registration.type + '/Finish')
          }
        },
      ],
      beforeEnter: (to, from, next) => {
        store.dispatch('getRegistration', to.params.hash).then(() => {
          next()
        }).catch((error) => {
          if ((error instanceof Error) && error.response && (error.response.status === 403)) {
            next({name: 'RegistrationCompleted', params: {hash: to.params.hash}})
          } else {
            next({name: 'Error'})
          }
        })
      }
    }
  ]
})

export default router
